@import './variables';
.about-section{
    background-color: $about-color;
    // background-image: url('../../assets/site/skills-background.png');
    background-image: url(https://personal-project1.s3.amazonaws.com/e6fa0bf1-59ab-4805-b899-8ffca492a98e-header-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    color: $text-color;
    height: 100vh;

    .about-main-text{
        padding: 220px 0px 0px 0px;
    
        .about-text{
            font-family: 'Montserrat', sans-serif;
            font-size: 3em;
            font-weight: 300;
            letter-spacing: .05em;
            padding: 17px;
            margin-bottom: 0px;
            text-align: center;
            color: $text-color;
        }
        .icon-flex{
            padding: 17px;
        
            .icon{
                display: inline-block;
                margin: 0px 6px 0px 6px;
                width: 55px;
                transition-duration: 1s;
            }
            .icon:hover{
                display: inline-block;
                margin: 0px 6px 0px 6px;
                transform: scale(2,2);
            }
            .icon a{
                color:$text-color;
                transition-duration: 1s;
            }
            .icon a:hover{
                color: $off-yellow;
            }
        }
    }
}
h1 span span {
    position: relative;
    animation: move-text-color .6s ease-out forwards;
    bottom: -1em;
    opacity: 0;
}
h1 span span:hover{
    animation: infinite;
}

@keyframes move-text-color {
    0% {
        bottom: -0.2em;
        opacity: 1;
        color: #116fc2;
    }
    
    50% {
        bottom: 0.2em;
        color: $off-yellow;
    }
    100% {
        bottom: 0;
        opacity: 1;
        color: white;
    }
}

@media (min-width: $breakpoint-tablet){
    .about-section{

        .about-main-text{
            padding: 300px 0px 540px 0px;

            .about-text{
                font-size: 4em;
            }
        }
    }   
}

@media (min-width: $breakpoint-desktop){
    .about-section{

        .about-main-text{
            padding: 200px 0px 470px 0px;

            .about-text{
                font-size: 4em;
            }
        }
    }   
}