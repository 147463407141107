.projects-section{
    background-color: $projects-color;
    padding: 50px 10px;
    color: $text-color;

    .projects-title{
        color: $off-yellow;
        font-weight: 200;
        font-size: 2.5em;
        padding-bottom: 16px;
        letter-spacing: .111111em;
    }

    .scango{
        padding:10px;
        border-radius: 12px 12px 12px 12px;
        margin-bottom: 30px;
        -webkit-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        -moz-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);

        .project-links{
            display: flex;
            justify-content: space-around;
            padding: 10px;
            font-size: 18px;
        }

        .project-links a {
            color: $off-yellow;
        }

        .project-links a:hover{
            color: #007bff;
        }

        .scanGoCarousel{
            width: 100%;
            margin: 0 auto;
            padding-bottom: 50px;

            .w-101{
                height: 400px;
            }
        }
    }

    .scango h2{
        letter-spacing: .11111111em;
        height: 40px;
        border-bottom: 1px solid rgb(255, 255, 110); 
        font-size: 26px;
    }

    .scango p{
        width: 96%;
        margin:0 auto;
        padding-bottom: 15px;
        font-size: 14px;
        text-align: left;
    }

    .journalize{
        padding:10px;
        margin-bottom: 30px;
        border-radius: 12px 12px 12px 12px;
        -webkit-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        -moz-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
    
        .project-links{
            display: flex;
            justify-content: space-around;
            padding: 10px;
            font-size: 18px;
        }

        .project-links a {
            color: $off-yellow;
        }

        .project-links a:hover{
            color: #007bff;
        }

        .journalizeCarousel{
            width: 80%;
            margin: 0 auto;
            padding-bottom: 50px;
            .w-1000{
                width: 100% !important;
            }
        }
    }

    .journalize h2{
        letter-spacing: .11111111em;
        height: 40px;
        border-bottom: 1px solid rgb(255, 255, 110); 
        font-size: 26px;
    }

    .journalize p{
        width: 95%;
        margin: 0 auto;
        padding-bottom: 15px;
        font-size: 14px;
        text-align: left;
    }

    .pixelArt{
        padding:10px;
        margin-bottom: 30px;
        border-radius: 12px 12px 12px 12px;
        -webkit-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        -moz-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);

        .project-links{
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
            padding: 10px;
        }

        .project-links a{
            width: 80px;
            font-size: 16px;
            color: $off-yellow;
        }

        .project-links a:hover{
            color: #007bff;
        }

        .pixelArtCarousel{
            width: 80%;
            margin: 0 auto;
            padding-bottom: 50px;
            .w-201{
                height: 230px;
            }
        }
    }
    .pixelArt h2{
        letter-spacing: .11111111em;
        height: 40px;
        border-bottom: 1px solid rgb(255, 255, 110); 
        font-size: 26px;
    }
    .pixelArt p{
        width: 95%;
        margin: 0 auto;
        padding-bottom: 15px;
        font-size: 14px;
        text-align: left;
    }
    .portfolio{
        padding:30px;
        border-radius: 12px 12px 12px 12px;
        -webkit-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        -moz-box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        box-shadow: 0px 0px 11px -3px rgba(255,255,255,1);
        
 
         p a{
            color: $off-yellow;
        }

         p a:hover{
            color: #007bff;
        }
    }
}
strong{
    color: #007BFF;
}

.carousel-control-prev-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23bfbfbf' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-prev-icon:hover{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23696969' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23bfbfbf' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon:hover{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23696969' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}



@media (min-width: $breakpoint-tablet){
    .projects-section{

        padding: 80px 10vw;
        .projects-title{
            font-size: 3em;
        }

        .scango{
            padding:30px;
            margin-bottom: 40px;
            .scanGoCarousel{
                width: 80%;
            }
        }

        .scango p{
            width: 80%;
            font-size: 18px;
        }

        .journalize{
            padding:30px;
            margin-bottom: 40px;
            
        }

        .journalize p{
            width: 80%;    
            font-size: 18px;
        }

        .pixelArt{
            margin-bottom: 40px;
          
            .pixelArtCarousel{

                .w-201{
                    height: 350px;
                }
            }
        }

        .pixelArt p{
            width: 80%;    
            font-size: 18px;
        }
    }
}
@media (min-width: $breakpoint-desktop){
    .projects-section{
        padding: 100px 18vw;
        .projects-title{
            font-size: 3em;
            padding-bottom: 50px;
        }
        .scango{
            margin-bottom: 60px;
            
            .scanGoCarousel{

                .w-101{
                    height: 500px;
                }
            }
        }
        .scango h2{
            font-size: 36px;
            height: 60px;
            margin-bottom: 20px;
        }
        .scango p{
            width: 80%;
            font-size: 18px;
        }
        .journalize{
            margin-bottom: 60px;
          
            .journalizeCarousel{
                width: 80%;

            }
        }
        .journalize h2{
            font-size: 36px;
            height: 60px;
            margin-bottom: 20px;
        }
        .journalize p{
            width: 80%;
            font-size: 18px;
        }
        .pixelArt{
            margin-bottom: 60px;
          
            .project-links{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                padding: 10px;
            }
            .project-links a{
                width: 100px;
            }

            .pixelArtCarousel{
                width: 80%;

            }
        }
        .pixelArt h2{
            font-size: 36px;
            height: 60px;
            margin-bottom: 20px;
        }
        .pixelArt p{
            width: 80%;
            font-size: 18px;
        }
        // .portfolio{
        //     padding:30px;
        // }
        
    }
}

@media (min-width: $breakpoint-wideDesktop){
    .projects-section{
        padding: 100px 18vw;
        .projects-title{
            font-size: 3em;
            padding-bottom: 50px;
        }
        .scango{
            margin-bottom: 60px;
            
            .scanGoCarousel{

                .w-101{
                    height: 500px;
                }
            }
        }
        .scango h2{
            // height: 70px;
            // margin-bottom: 20px;
        }
        .scango p{
            width: 80%;
            font-size: 18px;
        }
        .journalize{
            margin-bottom: 60px;
          
            .journalizeCarousel{
                width: 80%;

            }
        }
        .journalize h2{
            font-size: 36px;
        }
        .journalize p{
            width: 80%;
            font-size: 18px;
        }
        .pixelArt{
            margin-bottom: 60px;
          
            .project-links{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                padding: 20px;
            }

            .pixelArtCarousel{
                width: 80%;

            }
        }
        .pixelArt h2{
            font-size: 36px;
        }
        .pixelArt p{
            width: 80%;
            font-size: 18px;
        }
        
    }
}