@import './variables';

.contact-section{
    background-color: $contact-color;
    color: white;
    padding: 50px 10px;
    .email-alert{
        width: 300px;
        margin:0 auto;
    }
    .contact-title{
        color: $off-yellow;
        font-weight: 200;
        font-size: 2.5em;
        padding-bottom: 20px;
        letter-spacing: .111111em;
    }
    .email-form{
        width: 300px;
        margin: 0 auto;
    
        .confirmation-text{
            color: green;
            margin-bottom: 10px;
            font-size: 16px;
        }
    }
    .contact-icon-flex{
        padding: 25px;
    
        .icon{
            display: inline-block;
            margin: 0px 10px 0px 10px;
            width: 55px;
        }
        .icon a{
            color:$text-color;
        }
        .icon a:hover{
            color: $off-yellow;
        }
    }
}
.contact-section p{
    font-size: 16px;
}

footer {
    font-size: 12px;
    margin-bottom: 30px;
}
@media (min-width: $breakpoint-tablet){
    .contact-section{
        padding: 100px;

        .email-alert{
            width: 400px;
        }
        .contact-title{
            font-size: 3em;
            padding-bottom: 60px;
        }
        .email-form{
            width: 400px;    
        }
    }
    .contact-section p{
        font-size: 20px;
    }
    footer {
        margin-top: 140px;
        font-size: 12px;
    }
}

@media (min-width: $breakpoint-desktop){
    .contact-section{
        padding: 100px;
    
        .contact-title{
            font-size: 3em;
            padding-bottom: 50px;
            margin-bottom: 0px;
        }

        .email-form{
            width: 500px;    
        }
        .contact-icon-flex{
            padding: 40px;
        
            .icon{
                display: inline-block;
                margin: 0px 20px 0px 20px;
            }
        }
    }

    footer {
        margin-top: 0px;
        font-size: 12px;
    }
}