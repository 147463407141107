@import './variables';
.skills-section{
    
    background-color: $skills-color;
    background: radial-gradient(circle, rgba(92,92,92,1) 0%, rgba(0,0,0,1) 40%);
    padding: 50px 0px;
    color: $text-color;

    .skills-title{
        color: $off-yellow;
        font-weight: 200;
        font-size: 2.5em;
        letter-spacing: .111111em;
        padding-bottom: 20px;
    }
    .carousel-flex{
        height: 350px;
        display:flex;
        align-items: center;
        justify-content: space-around;
    
        .skills-left-arrow{
            color: #bfbfbf;
            background-color: transparent;
            border: none;
            height: 400px;
            outline:none;
        }
        .skills-left-arrow:hover{
            color: #696969;
            cursor: pointer;
            outline:none;
        }
        .stage {
        box-sizing: border-box;
        // border: 1px solid #CCC;
        position: relative;
        width: 120px;
        height: 240px;
        margin: 0vw 20vw;
        perspective: 250px;

            #skills-carousel {
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translateZ(-335px);
                transform-style: preserve-3d;
                transition: transform 1s;
                animation: spin 18s infinite linear;

                .carousel__cell {
                    position: absolute;
                    width: 100px;
                    height: 160px;
                    left: 10px;
                    top: 10px;
                    border: 2px solid black;
                    line-height: 116px;
                    font-size: 18px;
                    font-weight: 300 !important;
                    font-weight: bold;
                    text-align: center;
                    border-radius: 12px;
                    background-color: rgba(255, 255, 255, 0.8);
                        box-shadow: 0 2px 1rem 0 rgba(100, 100, 100, 0.5),
							0 -0.25rem .5rem rgb(102, 102, 102) inset,
							0 0.75rem .5rem rgba(114, 114, 114, 0.5) inset,
							0 0.25rem .5rem 0 rgb(117, 117, 117) inset;
                        p {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    .skill-img {
                        margin-top: 35px;
                        height: 65px;
                    }
                }
            }
        }

        .skills-right-arrow{
            color: #bfbfbf;
            background-color: transparent;
            border: none;
            height: 400px;
        }

        .skills-right-arrow:hover{
            color: #696969;
            cursor: pointer;
            outline:none;
        }
    }
}
@keyframes spin {
    from {transform: translateZ(-200px) rotateY(0); }
    to   {transform: translateZ(-200px) rotateY(-360deg); }
}

.btnr {
    margin: 50px 0px;
    color: white;
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
    letter-spacing: 3px;
    background-color:transparent;
    border: none;
  }

.btnr:hover {
    letter-spacing: 6px;
    transition: all 0.5s;
}

.btn-three {
    transition: all 0.5s;
    background-color: black; 
    position: relative;

  }

  .btn-three::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.1);
    // background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s;
    border:none;
  }

  .btn-three:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
  }

  .btn-three::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid $off-yellow;
    transform: scale(1.2,1.2);
  }

  .btn-three:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }

  .btn-two {
    color: white;
    transition: all 0.5s;
    position: relative; 
  }
  .btn-two span {
    z-index: 2; 
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; 
  }
  .btn-two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .btn-two::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(255,255,255,0.1);
  }
  .btn-two:hover::before {
    transform: rotate(-45deg);
    background-color: rgba(255,255,255,0);
  }
  .btn-two:hover::after {
    transform: rotate(45deg);
    background-color: rgba(255,255,255,0);
  }
  

.carousel__cell:nth-child(1) { transform: rotateY(  0deg) translateZ(200px);}
.carousel__cell:nth-child(2) { transform: rotateY( 30deg) translateZ(200px);}
.carousel__cell:nth-child(3) { transform: rotateY( 60deg) translateZ(200px);}
.carousel__cell:nth-child(4) { transform: rotateY( 90deg) translateZ(200px);}
.carousel__cell:nth-child(5) { transform: rotateY(120deg) translateZ(200px);}
.carousel__cell:nth-child(6) { transform: rotateY(150deg) translateZ(200px);}
.carousel__cell:nth-child(7) { transform: rotateY(180deg) translateZ(200px);}
.carousel__cell:nth-child(8) { transform: rotateY(210deg) translateZ(200px);}
.carousel__cell:nth-child(9) { transform: rotateY(240deg) translateZ(200px);}
.carousel__cell:nth-child(10) { transform: rotateY(270deg) translateZ(200px);}
.carousel__cell:nth-child(11) { transform: rotateY(300deg) translateZ(200px);}
.carousel__cell:nth-child(12) { transform: rotateY(330deg) translateZ(200px);}

@media (min-width: $breakpoint-tablet){
    .skills-section{
    background: radial-gradient(circle, rgba(92,92,92,1) 0%, rgba(0,0,0,1) 50%);
    padding: 80px 3vw;

        .skills-title{
            font-size: 3em;
            margin-bottom: 100px;
        }
        .carousel-flex{
            display: flex;
            justify-content: center;

            .skills-left-arrow{
                width: 40px;
            }

            .stage {
                // border: 1px solid #CCC;
                width: 240px;
                height: 290px;
                perspective: 350px;

                #skills-carousel {
                    transform: translateZ(-429px);

                    .carousel__cell {
                        width: 220px;
                        height: 170px;
                        background-color: rgba(255, 255, 255, 0.8);
                        box-shadow: 0 .5rem 1rem 0 rgba(100, 100, 100, 0.5),
							0 -0.25rem 1.5rem rgb(102, 102, 102) inset,
							0 0.75rem 0.5rem rgba(114, 114, 114, 0.5) inset,
							0 0.25rem 0.5rem 0 rgb(117, 117, 117) inset;
                        
                        p {
                            font-size: 22px;
                        }

                        .skill-img {
                            margin: 20px;
                            height: 120px;
                        }
                    }
                }
            }
            .skills-right-arrow{
                width: 40px;
            }
        }
    }
    @keyframes spin {
        from {transform: translateZ(-429px) rotateY(0); }
        to   {transform: translateZ(-429px) rotateY(-360deg); }
    }
    .carousel__cell:nth-child(1) { transform: rotateY(  0deg) translateZ(429px); }
    .carousel__cell:nth-child(2) { transform: rotateY( 30deg) translateZ(429px); }
    .carousel__cell:nth-child(3) { transform: rotateY( 60deg) translateZ(429px); }
    .carousel__cell:nth-child(4) { transform: rotateY( 90deg) translateZ(429px); }
    .carousel__cell:nth-child(5) { transform: rotateY(120deg) translateZ(429px); }
    .carousel__cell:nth-child(6) { transform: rotateY(150deg) translateZ(429px); }
    .carousel__cell:nth-child(7) { transform: rotateY(180deg) translateZ(429px); }
    .carousel__cell:nth-child(8) { transform: rotateY(210deg) translateZ(429px); }
    .carousel__cell:nth-child(9) { transform: rotateY(240deg) translateZ(429px); }
    .carousel__cell:nth-child(10) { transform: rotateY(270deg) translateZ(429px);}
    .carousel__cell:nth-child(11) { transform: rotateY(300deg) translateZ(429px);}
    .carousel__cell:nth-child(12) { transform: rotateY(330deg) translateZ(429px);}
}

@media (min-width: $breakpoint-desktop){
    .skills-section{
    background: radial-gradient(circle, rgb(92, 92, 92) 0%, rgba(0,0,0,1) 45%);
    padding: 100px 5vw;
    margin: 0 auto;

        .skills-title{
        padding-bottom: 20px;
        }

        .carousel-flex{
            display: flex;
            justify-content: center;

            .skills-left-arrow{
                width: 40px;
            }

            .stage {
                // border: 1px solid #CCC;
                margin: 0vw 25vw;
                width: 280px;
                height: 220px;
                perspective: 800px;

                #skills-carousel {
                    transform: translateZ(-522px);

                    .carousel__cell {
                        width: 260px;
                        height: 160px;
                        font-size: 28px;

                        .skill-img {
                            margin-top: 25px;
                            height: 100px;
                        }
                    }
                }
            }
            .skills-right-arrow{
                width: 40px;
            }
        }
    }

    .carousel__cell:nth-child(1) { transform: rotateY( 0deg) translateZ(522px); }
    .carousel__cell:nth-child(2) { transform: rotateY( 30deg) translateZ(522px); }
    .carousel__cell:nth-child(3) { transform: rotateY( 60deg) translateZ(522px); }
    .carousel__cell:nth-child(4) { transform: rotateY( 90deg) translateZ(522px); }
    .carousel__cell:nth-child(5) { transform: rotateY(120deg) translateZ(522px); }
    .carousel__cell:nth-child(6) { transform: rotateY(150deg) translateZ(522px); }
    .carousel__cell:nth-child(7) { transform: rotateY(180deg) translateZ(522px); }
    .carousel__cell:nth-child(8) { transform: rotateY(210deg) translateZ(522px); }
    .carousel__cell:nth-child(9) { transform: rotateY(240deg) translateZ(522px); }
    .carousel__cell:nth-child(10) { transform: rotateY(270deg) translateZ(522px);}
    .carousel__cell:nth-child(11) { transform: rotateY(300deg) translateZ(522px);}
    .carousel__cell:nth-child(12) { transform: rotateY(330deg) translateZ(522px);}

    @keyframes spin {
    from {transform: translateZ(-522px) rotateY(0); }
    to   {transform: translateZ(-522px) rotateY(-360deg); }
    }
}

@media (min-width: $breakpoint-wideDesktop){
    .skills-section{
    background: radial-gradient(circle, rgb(92, 92, 92) 0%, rgba(0,0,0,1) 35%);
    padding: 100px 0px;

        .skills-title{
        padding-bottom: 20px;
        }

        .carousel-flex{
            height: 400px;
            margin-bottom: 100px;
            display:flex;
            margin: 50px auto;
            .stage {
                // border: 1px solid #CCC;
                width: 400px;
                height: 320px;
                perspective: 800px;

                #skills-carousel {
                    transform: translateZ(-746px);

                    .carousel__cell {
                        width: 380px;
                        height: 220px;
                        font-size: 32px;

                        p {
                            font-size: 28px;
                            font-weight: 300;
                        }
                        .skill-img {
                            margin: 20px;
                            height: 160px;
                        }
                    }
                }
            }
        }
    }

    .carousel__cell:nth-child(1) { transform: rotateY( 0deg) translateZ(746px); }
    .carousel__cell:nth-child(2) { transform: rotateY( 30deg) translateZ(746px); }
    .carousel__cell:nth-child(3) { transform: rotateY( 60deg) translateZ(746px); }
    .carousel__cell:nth-child(4) { transform: rotateY( 90deg) translateZ(746px); }
    .carousel__cell:nth-child(5) { transform: rotateY(120deg) translateZ(746px); }
    .carousel__cell:nth-child(6) { transform: rotateY(150deg) translateZ(746px); }
    .carousel__cell:nth-child(7) { transform: rotateY(180deg) translateZ(746px); }
    .carousel__cell:nth-child(8) { transform: rotateY(210deg) translateZ(746px); }
    .carousel__cell:nth-child(9) { transform: rotateY(240deg) translateZ(746px); }
    .carousel__cell:nth-child(10) { transform: rotateY(270deg) translateZ(746px);}
    .carousel__cell:nth-child(11) { transform: rotateY(300deg) translateZ(746px);}
    .carousel__cell:nth-child(12) { transform: rotateY(330deg) translateZ(746px);}
    @keyframes spin {
    from {transform: translateZ(-746px) rotateY(0); }
    to   {transform: translateZ(-746px) rotateY(-360deg); }
    }
}