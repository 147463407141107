//Site background and text colors
$header-color: black;
$floating-nav-color: rgb(120, 120, 120);
$about-color: black;
$skills-color: black;
$projects-color: black;
$contact-color: black;
$header-link-color: #ff0;
$off-yellow:rgb(255, 255, 110); 
$text-color: #ffffff;
//Breakpoints
$breakpoint-tablet: 599px;
$breakpoint-desktop: 901px;
$breakpoint-wideDesktop: 1301px;
