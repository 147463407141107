@import "~bootstrap/scss/bootstrap";

.App {
    text-align: center;
}
a:hover{
  text-decoration: none;
  color: inherit; 
}
*{
  outline: none !important;
}
::-webkit-scrollbar {
    display: none;
}
button:focus {outline: none;}

button:active {outline: none;}

button{
  outline: none !important;
 }